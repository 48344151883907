export const adsConfig = {
  'div-gpt-ad-box-1': {
    breakpoint: {
      min: 970,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [
      [300,250]
    ],
    targeting: {
      pos: 'box-1'
    }
  },
  'div-gpt-ad-box-2': {
    breakpoint: {
      min: 970,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [
      [300, 250]
    ],
    targeting: {
      pos: 'box-2'
    }
  },
  'div-gpt-ad-box-3': {
    breakpoint: {
      min: 970,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [
      [300, 250]
    ],
    targeting: {
      pos: 'box-3'
    }
  },
  'div-gpt-ad-leaderboard-bottom': {
    breakpoint: {
      min: 728,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [
      [728, 90]
    ],
    targeting: {
      pos: 'leaderboard-bottom'
    },
  },
  'div-gpt-ad-mobile-leaderboard-1': {
    breakpoint: {
      min: 0,
      max: 969
    },
    size: [
      [320, 50]
    ],
    targeting: {
      pos: 'mobile-leaderboard-1'
    }
  },
  'div-gpt-ad-mobile-box-1': {
    breakpoint: {
      min: 0,
      max: 969
    },
    size: [
      [300, 250]
    ],
    targeting: {
      pos: 'mobile-box-1'
    }
  },
};
