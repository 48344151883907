import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Schema from '..';
import { getCarouselSchema } from '../../../utils/metaContentHelper';

const CarouselSchema = (props) => {
  const { ItemList } = props;

  return <Schema data={getCarouselSchema(ItemList)} />;
};

CarouselSchema.propTypes = {
  ItemList: PropTypes.array.isRequired
};

export default memo(CarouselSchema);
