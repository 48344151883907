import React, {PureComponent} from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { generateBlogPath } from '../../../../utils/urlHelpers/blog';
import { setGenericEvent } from '../../../../store/actions/dataLayer';
import { getAssetUrl } from '../../../../utils/commonHelper';
import './styles.css';
import { getMessages } from '../../../../tppServices/translations/messages';

class CategorySelector extends PureComponent {

  trackGenericClick = (event) => () => {
    if (event) {
      this.props.setGenericEvent('blog - navigation', event.action, event.label);
    }
  }

  render() {
    const { categories, intl: { formatMessage: t } } = this.props;
    const messages = getMessages();

    return (
      <div className="category-selector">
        <nav>
          <ul>
            {categories.map(
              ({name, img, event }, index) =>
                <li key={index}>
                  <a
                    href={generateBlogPath({}, {category: name})}
                    onClick={this.trackGenericClick(event)}
                  >
                    <figure>
                      <div className="image-container">
                        <img src={getAssetUrl(img)} alt="" loading="lazy"/>
                      </div>
                      <p className="category">{(t(messages.blog.categories[name])).toUpperCase()}</p>
                      <p className="description">{(t(messages.blog.categoriesDescription[name])).toUpperCase()}</p>
                    </figure>
                  </a>
                </li>
            )}
          </ul>
        </nav>
      </div>
    );
  }
}

CategorySelector.propTypes = {
  categories: PropTypes.array.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  setGenericEvent: PropTypes.func.isRequired,
};

export default connect(
  null,
  (dispatch) => bindActionCreators({
    setGenericEvent
  }, dispatch)
)(injectIntl(CategorySelector));
