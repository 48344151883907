import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Schema from '..';
import { getArticleSchema } from '../../../utils/metaContentHelper';

const ArticleSchema = (props) => {
  const { article } = props;

  return <Schema data={getArticleSchema(article)} />;
};

ArticleSchema.propTypes = {
  article: PropTypes.object.isRequired
};

export default memo(ArticleSchema);
