import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import { withCookies } from 'react-cookie';
import { frontloadConnect } from 'react-frontload';
import { connect } from 'react-redux';
import get from 'lodash/get';
import PropTypes from 'prop-types';

import { setGenericEvent } from '../../store/actions/dataLayer';
import { PortalConfigContext } from '../../config/portal';
import { generateBlogPath } from '../../utils/urlHelpers/blog';
import ErrorMessage from '../../components/ErrorMessage';
import DefaultLayout from '../../layouts/Default';
import * as utils from '../../store/utils';
import * as actions from '../../store/actions';
import NotFoundPage from '../NotFoundPage';
import BlogHome from './components/BlogHome';
import BlogArticle from './components/BlogArticle';
import {runOnce} from '../../utils/runOnceHelper';

class Blog extends PureComponent {
  onPaginationChange = (page, pageLabel) => {
    setGenericEvent('navigation - list', 'blog', pageLabel);
    window.scrollTo(0, 0);
  };

  getPaginationUrl = (page) => {
    const params = get(this.props, 'params', {});
    return generateBlogPath({page: page}, params);
  }

  getCurrentPage = () => {
    const params = get(this.props, 'params', {});
    return get(params, 'page', 1);
  }

  getMaxPages = (pageSize, articleCount) =>
    articleCount > pageSize ?
      Math.floor(articleCount / pageSize) + (articleCount % pageSize ? 1 : 0) :
      1;

  renderBlogLayout() {
    const { errors, articles, isWorking, params } = this.props;
    const relatedArticles = get(articles, 'relatedArticles', {});
    const articlesNotFound = get(articles, 'records', []).length ? false : true;
    const context = get(this, 'context', {});
    const pagesContext = get(context, 'pages', {});
    const blogContext = get(pagesContext, 'blog', {});
    const pagination = get(blogContext, 'pagination', {});
    const pageHorizon = get(pagination, 'pageHorizon', 3);
    const pageSize = get(pagination, 'pageSize', 9);
    const showArticle = get(params, 'name', '').length ? true : false;
    const article = articles.records[0];
    const metaRobots = showArticle && article?.seo?.noindex ?  'noindex, max-image-preview:large' : 'max-image-preview:large';

    return (
      errors || articlesNotFound ?
        <NotFoundPage {...this.props} />
        :
        <DefaultLayout {...this.props} pageType="EditorialPage">
          <Helmet>
            <meta name="robots" content={metaRobots} />
          </Helmet>
          {showArticle ?
            <BlogArticle
              article={article}
              relatedArticles={relatedArticles}
              {...this.props}
            /> :
            <BlogHome
              isLoading={isWorking}
              pageHorizon={pageHorizon}
              currentPage={this.getCurrentPage()}
              maxPages={this.getMaxPages(pageSize, articles.count)}
              getPaginationUrl={this.getPaginationUrl}
              onPaginationChange={this.onPaginationChange}
              {...this.props}
            />
          }
        </DefaultLayout>
    );
  }

  render() {
    const { errors, success, statusCode } = this.props;

    if ((errors && statusCode < 500) || success) {
      return (this.renderBlogLayout());
    }
    return <ErrorMessage {...this.props} />;
  }
}

const mapStateToProps = state => {
  return {
    isWorking: get(state.app, 'isWorking', {}),
    params: get(state.app, 'params', {}),
    articles: get(state.app, 'data', {}),
    success: state.app.success,
    errors: state.app.errors,
    statusCode: state.app.statusCode
  };
};

Blog.propTypes = {
  params: PropTypes.object,
  isWorking: PropTypes.bool,
  articles: PropTypes.object,
  errors: PropTypes.bool,
  success: PropTypes.bool,
  statusCode: PropTypes.number
};

Blog.contextType = PortalConfigContext;
// we create a function that will run only once bypassing multiple renders.
const justFirstTime = runOnce();
export default withCookies(connect(
  mapStateToProps,
  null
)(frontloadConnect(({ dispatch, location, cookies, debug = process.env.REACT_APP_LOCAL_DEBUG }) => {
  if (utils.isServer()) {
    return dispatch(actions.getBlogContent(location.pathname, cookies.cookies));
  }
  if (actions.shouldGetData(location)) {
    return dispatch(actions.getBlogContent(location.pathname, cookies.cookies));
  }
  // this only useful for development, no need to unit test
  /* istanbul ignore next */
  if (justFirstTime() && debug) {
    return dispatch(actions.getBlogContent(location.pathname, cookies.cookies));
  }
}, {
  onUpdate: true,
  onMount: true
})(Blog)));
