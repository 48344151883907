import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import PaginationItem from '../PaginationItem';
import './styles.css';

const Pagination = (props) => {
  const {
    current,
    onPageClick,
    pageHorizon,
    getUrl,
    nextPageLabel,
    previousPageLabel,
    itemLinkNoFollow,
    className,
    total
  } = props;

  const onClick = (page, pageLabel) => (e) => {
    if (page === current) {
      return e.preventDefault();
    }
    onPageClick(page, pageLabel);
  };

  const getPages = () => {
    const pages = [];

    const leftPageHorizon = Math.floor(pageHorizon / 2);
    const startingPage =
      current - leftPageHorizon <= 0 ? 1 : current - leftPageHorizon;

    for (
      let i = startingPage;
      i <= total && i < startingPage + pageHorizon;
      i++
    ) {
      pages.push({
        active: i === current,
        disabled: false,
        children: i,
        href: getUrl(i),
        type: i === current ? 'selected' : '',
        onClick: onClick(i, `page ${i}`),
        nofollow: itemLinkNoFollow
      });
    }

    pages.unshift({
      prev: true,
      disabled: current === 1,
      children: '<',
      href: getUrl(current === 1 ? 1 : current - 1),
      onClick: onClick(current === 1 ? 1 : current - 1, previousPageLabel),
      nofollow: itemLinkNoFollow
    });

    pages.push({
      next: true,
      disabled: current === total,
      children: '>',
      href: getUrl(current >= total ? total : current + 1),
      onClick: onClick(current >= total ? total : current + 1, nextPageLabel),
      nofollow: itemLinkNoFollow
    });

    return pages;
  };

  // if the total number of pages is 0 then return an empty string
  if (!total || current > total) {
    return '';
  }

  const pagesComp = getPages();

  return (
    <ul
      id="pagination_component"
      className={classnames('pagination-container', {
        [className]: Boolean(className)
      })}
    >
      {pagesComp.map((page, i) => (
        <PaginationItem key={i} {...page} />
      ))}
    </ul>
  );
};

Pagination.propTypes = {
  /** specify any custom class for the component */
  className: PropTypes.string,
  /** current page */
  current: PropTypes.number.isRequired,
  /** gets the url for a given page number */
  getUrl: PropTypes.func.isRequired,
  /** callback for pagination clicks */
  onPageClick: PropTypes.func.isRequired,
  /** number of pages to display forward */
  pageHorizon: PropTypes.number,
  /** total number of pages */
  total: PropTypes.number.isRequired,
  /** next page label*/
  nextPageLabel: PropTypes.string,
  /** previous page label*/
  previousPageLabel: PropTypes.string,
  /** nofollow html value */
  itemLinkNoFollow: PropTypes.bool
};

Pagination.defaultProps = {
  className: '',
  pageHorizon: 5
};

export default Pagination;
