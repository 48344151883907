import React from 'react';
import {connect} from 'react-redux';
import {withCookies} from 'react-cookie';
import get from 'lodash/get';
import DefaultLayout from '../../layouts/Default';

import Status from '../../components/Status';
import Link from '../../components/SEO/Link';
import './styles.css';

import {useTPPServices} from '../../tppServices/tppDIHooks';
import { getMessages } from '../../tppServices/translations/messages';

const NotFound = (props) => {
  const {routesConstants, currentI18n} = useTPPServices();
  const t = currentI18n.intl.formatMessage;
  const {HOME_URL_ROOT, RESOURCES_URL_ROOT, SEARCH_URL_ROOT, SELL_URL_ROOT} = routesConstants;
  const messages = getMessages();
  return (
    <DefaultLayout {...props} pageType="NotFound">
      <Status {...props} message="404"/>
      <div id="main-content" className="error-not-found">
        <h1 className="h1-notfound">{t(messages.error.notFound.title)}</h1>
        <h2>{t(messages.error.notFound.subtitle)}</h2>
        {t(messages.error.notFound.intro)}
        <ul>
          <li>{t(messages.error.notFound.option0, {
            link: <Link href={HOME_URL_ROOT}>{t(messages.error.notFound.home)}</Link>
          })}</li>
          <li>{t(messages.error.notFound.option1)}</li>
          <li>{t(messages.error.notFound.option2)}
            <ul>
              <li>
                {t(messages.error.notFound.action0, {
                  link: <Link href={SEARCH_URL_ROOT}>{t(messages.error.notFound.find)}</Link>
                })}
              </li>
              <li>
                {t(messages.error.notFound.action1, {
                  link: <Link href={SELL_URL_ROOT}>{t(messages.error.notFound.sell)}</Link>
                })}
              </li>
              <li>
                {t(messages.error.notFound.action2, {
                  link: <Link href={RESOURCES_URL_ROOT}>{t(messages.error.notFound.services)}</Link>
                })}
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </DefaultLayout>
  );
};

const mapStateToProps = state => {
  return {
    params: get(state.app, 'params', {}),
    search: get(state.app, 'data.search', {}),
    sponsored: get(state.app, 'data.sponsored', {}),
    facets: get(state.app, 'data.facets', {}),
    isWorking: state.app.isWorking,
    componentWorking: state.app.componentWorking,
    success: state.app.success,
    errors: state.app.errors,
    message: state.app.message,
    user: state.app.user,
    myboats: state.app.myboats
  };
};

export default withCookies(connect(
  mapStateToProps,
  null
)(NotFound));
